import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./DetailsPage.css";
// import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { BsArrowReturnLeft } from "react-icons/bs";
import { RxDividerVertical } from "react-icons/rx";
import ExploreImage from "../ExploreImage/ExploreImage";
import { WorkContext } from "../../Context/WorkContext";

const DetailsPage = () => {
  const { id } = useParams();
  const { workData } = useContext(WorkContext);

  const [selectedService, setSelectedService] = useState(null);

  useEffect(() => {
    // Find the selected service when workData is available
    const service = workData.find((service) => service.id === Number(id));
    setSelectedService(service);
  }, [id, workData]);

  if (!selectedService) {
    return <div className="loading-spinner"></div>; // or handle the loading state as needed
  }
  // videoExt =[".mp4",".webm"]
  const isVideo =
    selectedService.Banner &&
    (selectedService.Banner.toLowerCase().endsWith(".mp4") ||
      selectedService.Banner.toLowerCase().endsWith(".webm"));

  return (
    <section>
      <div className="container-fluidr">
        <div className="row">
          <div className="col-lg-12 work-head">
            <h4 className="sticky">
              <Link to="/">
                {" "}
                <BsArrowReturnLeft className="text-success font-weight-bold" />{" "}
                Home
              </Link>
              <RxDividerVertical />{" "}
              <Link to="/Work" className="text-danger">
                Work
              </Link>{" "}
              <RxDividerVertical /> {selectedService.title}
            </h4>{" "}
            <h1>{selectedService.title}</h1>
            <h4>{selectedService.Paragraph}</h4>
          </div>
        </div>
      </div>
      <div className="container-fluid work-con">
        <div className="row">
          <div className="col-lg-12">
            {isVideo ? (
              <video preload muted loop poster autoPlay>
                <source src={selectedService.Banner} />
              </video>
            ) : (
              <img
                src={selectedService.Banner}
                alt={selectedService.title + "banner"}
              ></img>
            )}
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row ul-li-work">
          <div className="col-lg-7 col-md-6 col-sm-12 ">
            <ul>
              <h2>The Challenge</h2>
              {Object.entries(selectedService).map(
                ([key, value]) =>
                  key.startsWith("TheChallenge") && <li key={key}>{value}</li>
              )}
            </ul>
          </div>
          <div className="col-lg-4 offset-lg-1 col-md-6 col-sm-12">
            <ul>
              <h2>OUR ROLE</h2>
              {Object.entries(selectedService).map(
                ([key, value]) =>
                  key.startsWith("ourrole") && <li key={key}>{value}</li>
              )}
            </ul>
          </div>
        </div>
        <div className="row work-data-img">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="left-side">
              <h2>{selectedService.title}</h2>
              <h5>
                {Object.entries(selectedService).map(
                  ([key, value]) =>
                    key.startsWith("task") && <li key={key}>{value}</li>
                )}
              </h5>
            </div>
          </div>
          <div className="col-lg-8 col-md-6 col-sm-12">
            <div className="Work-image">
              <div>
                {Object.entries(selectedService).map(
                  ([key, value]) =>
                    key.startsWith("img") && (
                      <img src={value} alt={selectedService.title}></img>
                    )
                )}
              </div>
            </div>
          </div>
        </div>

        <ExploreImage />
      </div>
    </section>
  );
};

export default DetailsPage;
