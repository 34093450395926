import React from "react";

const WordpressDeveloperTab = () => {
  return (
    <div id="tab1" className="tabcontent">
      <div className="row">
        <div className="col-lg-12">
          <h3 className="mt-5">Wordpress Developer</h3>
          <div className="spacer1"></div>
          <h4>Open Position : 2 Wordpress Developer</h4>
          <h5>
            WordPress developer with 3-5 years of experience of working with
            WordPress plugins and customized themes. Must be proficient in PHP
            and MySQL. Knowledge in HTML5, CSS3, jQuery, Ajax, and Bootstrap
            framework is a big plus. Candidate must have good understanding of
            backend and fronted code and be able to code the designs supplied by
            our in-house design team.
          </h5>
          <h5>
            We are looking for an organized, efficient, and detail oriented
            team-player, with a positive, “can-do” attitude and exploring and
            discovery drive.
          </h5>
          <h5>
            {" "}
            The ideal team player should share openly and willingly, communicate
            constructively and work as a problem-solver.
          </h5>
          <h5>
            {" "}
            Candidates who thrive within a laid back atmosphere with a friendly
            and dedicated team should apply.
          </h5>
          <h5> This is a full-time position.</h5>
          <h4>
            Qualified candidates MUST provide the following in PDF format:
          </h4>
          <h5>Cover Letter.</h5> <br />
          <h5>Resume.</h5> <br />
          <h5>Salary.</h5>
          <br />
          <h5>History</h5>
          <br />
          <h5>References</h5> <br />
          <h5>Portfolio</h5> <br />
          {/* <div>
            <b>
              Qualified candidates MUST provide the following in PDF format:
            </b>
          </div> */}
          {/* <ul style={{ marginTop: "10.4px" }}>
            <li>Cover Letter</li>
            <li>Resume</li>
            <li>Salary</li>
            <li>History</li>
            <li>References</li>
            <li> Portfolio</li>
          </ul> <br/> */}
          <h4>Remuneration:</h4>
          <br />
          <h5>Negotiable depending on relevant experience and design test</h5>
          {/* <div>
            <b>Remuneration:</b>
            <p>Negotiable depending on relevant experience and design test</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default WordpressDeveloperTab;
