import BannerBrand from "../images/brand.webp";
import BannerCreative from "../images/creative_design.webp";
import BannerPpt from "../images/corporate_ppt.webp";
import BannerPackage from "../images/product_package.webp";
import BannerVideo from "../images/Intro_Video.mp4";
import BannerDigital from "../images/digital.webp";
import BannerEnv from "../images/brand.webp";
import BannerSocial from "../images/social_media.webp";
import BannerAdv from "../images/adv.webp";

const ContentService = [
  {
    id: 1,
    name: "Branding",
    description: "Define your brand",
    descriptionService:
      "Unlock the power of a strong corporate brand with our comprehensive branding solution.",
    nameParagraph:
      "From logo design to a cohesive brand identity, we create unique, memorable brands that resonate with your target audience.",
    banner: BannerBrand,
    nameNote:
      "At Srishti India, we specialize in crafting business branding strategies that drive success. Our branding services include:",
    nameNote2: "• Logo design and visual identity creation",
    nameNote3: "• Brand strategy and development",
    nameNote4: "• Marketing material design for cohesive brand communication",
    nameNote5:
      "We ensure a consistent and seamless branding experience that connects your brand to your audience. Let’s build a brand your customers will love.",
    link: "branding",
  },
  {
    id: 2,
    name: "Creative Designs",
    descriptionService:
      "Creative designs that uplift and are visually captivating for a lasting impression",
    description: "Passionate about designs",
    nameParagraph:
      "Good design equals good business. It’s this irrefutable principle that we at Srishti wholeheartedly embrace.",
    banner: BannerCreative,
    nameNote: "Our creative design services include:",
    nameNote2: "• Logo and brand identity design that strengthens your brand",
    nameNote3: "• Graphic design for enhanced brand recognition",
    nameNote4: "• Product packaging design that reflects your brand’s identity",
    nameNote5:
      "Let’s create designs that your audience will remember and love.",
    link: "design",
  },
  {
    id: 3,
    name: "Corporate PPTs",
    descriptionService:
      "Communicating with success starts with a stunning and a compelling presentation. It is your first contact with potential client that should be captivating.",
    description: "Deploying inspired presentaions",
    nameParagraph:
      "At Srishti, we sift through reams of data to craft power point presentations that readily resonate with your audience. Our designers excel at narrating your story from scratch through communication that is strategy-led and brand-focussed.",
    banner: BannerPpt,
    nameNote:
      "Our corporate presentation services focus on impactful storytelling and engaging design. We offer:",
    nameNote2:
      "• Customized PowerPoint presentations that align with your brand / product / service",
    nameNote3:
      "• Simplifying complex data through creative visualization techniques.",
    nameNote4:
      "• Developing industry-specific presentations that resonate with target audiences.",
    nameNote5:
      "We create presentations that effectively communicate your business goals and resonate with your audience.",
    link: "ppt",
  },
  {
    id: 4,
    name: "Product Package",
    descriptionService:
      "A product's packaging is the gateway to any brand, let us make that impression unforgettable.",
    description: "Custom package designs",
    nameParagraph:
      "Our premium product packaging solutions are designed to captivate, protect, and elevate your products, ensuring they stand out on the shelf and in the hands of your customers.",
    banner: BannerPackage,
    nameNote:
      "Srishti India offers end-to-end product packaging solutions, including:",
    nameNote2: "• Concept-to-delivery packaging design",
    nameNote3: "• Customizable designs and sustainable materials",
    nameNote4: "• Eye-catching packaging that enhances your product’s appeal",
    nameNote5:
      "Create packaging that stands out and strengthens your brand’s identity. ",
    link: "productpackaging",
  },
  {
    id: 5,
    name: "Video Production",
    description: "Visual depiction of your brand ",
    descriptionService:
      "Videos are the best means of communication for inspiring or converting your audience.",
    nameParagraph:
      "At Srishti, we delight in crafting compelling corporate films.",
    banner: BannerVideo,
    nameNote: "Our video production services include:",
    nameNote2: "• Corporate and product films that showcase your brand",
    nameNote3: "• Explainer videos for clear communication of complex ideas",
    nameNote4: "• Motion graphic design to bring your brand’s story to life",
    nameNote5:
      "We can help you engage and captivate your audience with professional video production services.",
    link: "videoproduction",
  },
  {
    id: 6,
    name: "Digital Solutions",
    description: "Online presence for brands",
    descriptionService: "Define your brand",
    nameParagraph:
      "In this day and age, it is imperative to have a strong and highly distinctive digital presence. This is especially essential as your consumers are always online. Our array of digital services is conceived to establish a compelling online presence, for your brand—a presence that is capable of carving an indelible place in the hearts and minds of your customers.",
    banner: BannerDigital,
    nameNote: "Our wide range of digital services:",
    nameNote2: "Website design",
    nameNote3: "Email marketing",
    nameNote4: "E-brochures",
    nameNote5: "Website design",
    nameNote6: "Video email marketing",
    nameNote7: "PowerPoint presentations",
    nameNote8:
      "Additionally, our digital team is also highly adept in creating user interfaces that that will engage your audiences and deliver results. We also extend social integration, so your brand is right where your consumers are, boasting of a strong presence on all the platforms favoured by your consumers.",
    nameNote9:
      "Contact us today to propel your business to the next level with our sought- after digital services.",
    link: "digitalsolution",
  },
  {
    id: 7,
    name: "Social Media",
    // description:"Visual depiction of your brand ",
    descriptionService:
      "In day to day trends Social media is the prime key to marketing providing a way to stay connected with your target audience.",
    nameParagraph:
      "With the right strategy and tools, we can make a powerful and effective marketing to boost your business. The key is to create a plan that is tailored to your company's goals and objectives.",
    banner: BannerSocial,
    nameNote:
      "Srishti India offers social media marketing services that enhance your online presence. Our services include: ",
    nameNote2: "• Social media strategy development for targeted growth",
    nameNote3: "• Content calendar creation for consistent engagement",
    nameNote4: "• Social media performance audits and insights",
    nameNote5:
      "Allow us to help you grow your brand’s reach with expert social media management and marketing. ",
    // link:"/videoproduction"
  },
  {
    id: 8,
    name: "Environmental Graphics",
    description: "Online presence for brands",
    descriptionService:
      "Creating experiences, way finding and communicating identity through our design principles shaping the office space into a creative space.",
    nameParagraph:
      "Envonmental Graphic Design (EGD) embraces many design disciplines including graphic, interior, landscape, and industrial design all concerned with the visual aspects of communicating identity and information, and shaping the idea of creating experiences that connect people to place.",
    banner: BannerEnv,
    nameNote:
      "At Srishti India, we design environmental graphics that elevate user experiences with our professional and innovative designs. Our services include:",
    nameNote2: "• Signage and wayfinding solutions for clear navigation",
    nameNote3: "• Wall, floor, and sculptural graphic designs",
    nameNote4: "• Branded installations that enhance the space’s identity",
    nameNote5:
      "Create memorable, immersive environments that leave a lasting impression with our custom environmental graphics.",
  },
  {
    id: 9,
    name: "Advertising",
    // description:"Online presence for brands",
    descriptionService:
      "Let us plan and work towards your brand with the right mix of creative and messaging to achieve your goals.",
    nameParagraph:
      "Any advertising campaign, be it tactical or strategic, defining and redefining goals and objectives, developing strategies, and evaluating advertising results are the processes involved.",
    banner: BannerAdv,
    nameNote: "Srishti India specializes in:",
    nameNote2: "• Targeted advertising campaigns for maximum reach",
    nameNote3: "• Integrated marketing communication across multiple platforms",
    nameNote4:
      "• Corporate brand identity development through creative campaigns",
    nameNote5:
      "Create advertising campaigns that resonate with your audience and drive results.",
  },
];
export default ContentService;
