import React, { useContext } from "react";
import { BsArrowReturnLeft } from "react-icons/bs";
import { RxDividerVertical } from "react-icons/rx";
import { Link, useParams } from "react-router-dom";
import "./DesignService.css";
import { ServiceContext } from "../../Context/ServiceContext";
import ExploreImage from "../ExploreImage/ExploreImage";

const DesignService = () => {
  const { ContentService } = useContext(ServiceContext);
  const { id } = useParams();

  const SelectedService = ContentService.find(
    (service) => service.id === Number(id)
  );

  const isVideo =
    SelectedService.banner &&
    SelectedService.banner.toLowerCase().endsWith(".mp4");

  if (!SelectedService) {
    return <div>Service not found</div>;
  }

  return (
    <section>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 home-head">
            <h4 className="sticky">
              <BsArrowReturnLeft className="text-success font-weight-bold" />{" "}
              <Link to="/">Home</Link>
              <RxDividerVertical />{" "}
              <Link to="/service" className="text-danger">
                Services
              </Link>
              <RxDividerVertical />
              {SelectedService.name}
            </h4>
            <h1>{SelectedService.name}</h1>
            {/* this also making dyananmic */}
            <h4>{SelectedService.nameParagraph}</h4>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 ser-img">
            {isVideo ? (
              <video width="100%" height="auto" autoPlay preload="true">
                <source src={SelectedService.banner} type="video/mp4"></source>
                Your browser does not support the video tag.
              </video>
            ) : (
              <img src={SelectedService.banner} alt="banner" />
            )}
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 nameNote">
            {Object.entries(SelectedService).map(([key, value]) => {
              return (
                key.startsWith("nameNote") && (
                  <h4 className="d-block" key={key}>
                    {value}
                  </h4>
                )
              );
            })}
          </div>
        </div>
      </div>
      <ExploreImage />
    </section>
  );
};

export default DesignService;
