import React from "react";

const UIUXDesignerTab = () => {
  return (
    <div id="tab5" className="tabcontent">
      <div className="row">
        <div className="col-lg-12">
          <h3 className="mt-5">UI / UX Designer</h3>
          <div className="spacer1"></div>

          <h4>Open Position : 1 UI / UX Designer</h4>

          <h5>
            Relevant 2-4 years of experience working with product companies or
            consumer-facing applications.
          </h5>
        </div>

        <div className="col-lg-12">
          {/* <ul> */}
          <h5>
            Strong experience designing scalable interfaces across a wide range
            of technologies & form factors.
          </h5>
          <h5>
            Proven track record in delivering business results through design &
            innovation.
          </h5>
          <h5>
            Proven ability in pushing the boundaries of the medium at hand.
          </h5>
          <h5>Exceptional portfolio & meticulous attention to detail</h5>
          <h5>
            Good to have - Working knowledge of front-end languages (Web &
            Native Apps)
          </h5>
          <h5>Experience working collaboratively with teams and clients</h5>
          <h5>
            {" "}
            Effectively communicate with developers through implementation
            phase.
          </h5>
          <h5>
            Good to have - Hands-on experience with front-end engineering or
            functional prototyping is a huge bonus
          </h5>
          {/* </ul> */}
          <h4>Key Duties:</h4>
          {/* <ul> */}
          <h5>
            {" "}
            Expertise in Design tools like Photoshop, Coreldraw, Adobe
            Illustrator, etc.
          </h5>
          <h5>
            Expertise in UI Designing & Graphic Designing for the following:
          </h5>
          {/* <ul> */}
          <p> Responsive Web Layouts</p>
          <p> Mobile Apps Designs</p>
          <p> Logos / Brochures / Icons Banner Ads</p>
          <p>
            {" "}
            Creative Graphics and Infographics (Used for social media promotion
            on Facebook, Twitter, etc.)
          </p>
          <p>Landing Page Designs</p>
          <p>Newsletter Designs</p>
          {/* </ul> */}
          <h5>
            {" "}
            Deep knowledge of User Interface (UI) design, User Experience (UX),
            Usability, Visual Design and Graphic Design.
          </h5>
          <h5>
            Creative and produce innovative original digital graphic works.
          </h5>
          <h5>
            {" "}
            Working experience on UI Frameworks like bootstrap, node. js,
            angular. js etc.
          </h5>
          <h5>
            {" "}
            Wireframe Design, Mockup Design, User Experience design, Product
            Design.
          </h5>
          <h5>
            {" "}
            Ability and desire to work in a fast-paced, collaborative
            environment.
          </h5>
          <h5>
            Relentlessly passionate about the product and crafting the right,
            pixel-perfect solution.
          </h5>
          <h5>
            Excellent understanding of visual design, communication design,
            graphic design and advertising.
          </h5>
          {/* </ul> */}
          <h4>Remuneration:</h4> <br />
          <h5>Negotiable depending on relevant experience and design test</h5>
        </div>
      </div>
    </div>
  );
};

export default UIUXDesignerTab;
