import React from "react";
import AutoChangeContent from "../../Assets/Datas/Autochangecontent";
import { careerContent } from "../../Assets/Datas/ContentData";
import image2 from "../../Assets/images/hiring.webp";
import "../Career/Career.css";
import ReactTab from "../Career/ReactTab";
import CareeForm from "../Career/CareeForm";

const Career = () => {
  return (
    <>
      <div className="section career-section">
        {/* <BreadCrumb /> */}
        <div className="container-fluid career" data-aos="fade-up">
          <div className="row career-page">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <h1>{careerContent.heading}</h1>
            </div>
            <div className="col-lg-8 col-md-6 col-sm-12" data-aos="fade-up">
              {/* <AutoChangeContent content={[{careerContent }]} /> */}

              <h4>{careerContent.paragraph}</h4>
              {/* <AutoChangeContent content={[{...careerContent,image:image2,...careerContent }]} /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="careerimage mt-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12" data-aos="fade-up">
              <AutoChangeContent
                content={[{ image: image2 }]}
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <ReactTab data-aos="fade-up" />

      <CareeForm data-aos="fade-up" />
    </>
  );
};

export default Career;
