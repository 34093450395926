import React from "react";

function SocialMediaAccountManager() {
  return (
    <div id="tab1" className="tabcontent">
      <div className="row">
        <div className="col-lg-12">
          <h3 className="mt-5">Social Media Account Manager</h3>
          <div className="spacer1"></div>
          {/* <h4>Open Position : 1 Social Media Account Manager</h4> */}
          <h5>
            We are looking for a talented and strategic{" "}
            <strong>Social Media Account Manager</strong> to manage customer
            interactions, develop strong social media strategies, and guide
            clients in scaling their businesses. The ideal candidate will have a
            passion for creative storytelling, data-driven decision-making, and
            fostering client relationships.
          </h5>
          <h4>Key Responsibilities: </h4>
          <ul className="jd-list">
            <li>
              {" "}
              <h5>
                Develop and execute effective social media strategies for
                clients.
              </h5>
            </li>
            <li>
              {" "}
              <h5>
                Manage day-to-day customer interactions across all social media
                platforms.
              </h5>
            </li>
            <li>
              {" "}
              <h5>
                Create and oversee engaging content plans to drive growth and
                visibility.
              </h5>
            </li>
            <li>
              {" "}
              <h5>
                Monitor analytics and create performance reports with actionable
                insights.
              </h5>
            </li>
            <li>
              {" "}
              <h5>
                Collaborate with the creative team to ensure brand consistency.
              </h5>
            </li>
            <li>
              {" "}
              <h5>Address customer queries promptly and professionally.</h5>
            </li>
            <li>
              {" "}
              <h5>
                Stay updated with the latest social media trends and tools.
              </h5>
            </li>
            <li>
              {" "}
              <h5>
                Provide strategic guidance to clients for scaling their social
                media presence.
              </h5>
            </li>
          </ul>
          <h4>Required Skills and Qualifications:</h4>
          <ul className="jd-list">
            <li>
              {" "}
              <h5>
                Proven experience as a Social Media Account Manager or similar
                role.
              </h5>
            </li>
            <li>
              {" "}
              <h5>
                Strong knowledge of social media platforms (Instagram, Facebook,
                LinkedIn, etc.).
              </h5>
            </li>
            <li>
              {" "}
              <h5>
                Experience with social media management tools (e.g., Hootsuite,
                Buffer, Sprout Social).
              </h5>
            </li>
            <li>
              {" "}
              <h5>Excellent written and verbal communication skills.</h5>
            </li>
            <li>
              {" "}
              <h5>
                Strong analytical skills to interpret data and provide
                actionable insights.
              </h5>
            </li>
            <li>
              {" "}
              <h5>Creative mindset with attention to detail.</h5>
            </li>
            <li>
              {" "}
              <h5>
                Ability to work in a fast-paced, collaborative environment.
              </h5>
            </li>
          </ul>
          <h4>Qualifications: </h4>
          <ul className="jd-list">
            <li>
              <h5>
                Bachelor’s degree in Marketing, Communications, or a related
                field.
              </h5>
            </li>
            <li>
              <h5>Certification in Social Media Marketing is a plus.</h5>
            </li>
            <li>
              <h5>
                Experience working with international clients (USA & Europe
                focus).
              </h5>
            </li>
          </ul>
          <h4>What We Offer: </h4>
          <ul className="jd-list">
            <li>
              <h5>Competitive salary and growth opportunities.</h5>
            </li>
            <li>
              <h5>
                Dynamic work environment with a focus on creativity and
                innovation.
              </h5>
            </li>
            <li>
              <h5>Opportunity to work with global clients.</h5>
            </li>
          </ul>

          <h4>
            If you're passionate about creating impactful social media
            strategies and building meaningful client relationships, we’d love
            to hear from you!
          </h4>
        </div>
      </div>
    </div>
  );
}

export default SocialMediaAccountManager;
