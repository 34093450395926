import React, { useState } from "react";
import "./Tab.css";
import WordpressDeveloperTab from "./WordPressDeveloperTab";
import WebDesignerTab from "./WebDesignerTab ";
import WebDeveloperTab from "./WebDeveloperTab";
import SocialMediaAccountManager from "./SocialMediaAccountManager";
import GraphicDesignerTab from "./GraphicDesignerTab";
import UIUXDesignerTab from "./UIUXDesignerTab";
import PPTDesignerTab from "./PPTDesignerTab";

const JobTabs = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const openCity = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <section className="container-fluid reac-tab">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-3">
              <div className="tab">
                <button
                  className={`tablinks ${activeTab === "tab1" ? "active" : ""}`}
                  onClick={() => openCity("tab1")}
                >
                  SOCIAL MEDIA MANAGER
                </button>

                <button
                  className={`tablinks ${activeTab === "tab2" ? "active" : ""}`}
                  onClick={() => openCity("tab2")}
                >
                  WORDPRESS DEVELOPER
                </button>

                <button
                  className={`tablinks ${activeTab === "tab3" ? "active" : ""}`}
                  onClick={() => openCity("tab3")}
                >
                  WEB DESIGNER
                </button>

                <button
                  className={`tablinks ${activeTab === "tab4" ? "active" : ""}`}
                  onClick={() => openCity("tab4")}
                >
                  WEB DEVELOPER
                </button>

                <button
                  className={`tablinks ${activeTab === "tab5" ? "active" : ""}`}
                  onClick={() => openCity("tab5")}
                >
                  GRAPHIC DESIGNER
                </button>

                <button
                  className={`tablinks ${activeTab === "tab6" ? "active" : ""}`}
                  onClick={() => openCity("tab6")}
                >
                  UI/UX DESIGNER
                </button>

                <button
                  className={`tablinks ${activeTab === "tab7" ? "active" : ""}`}
                  onClick={() => openCity("tab7")}
                >
                  PPT DESIGNER
                </button>
              </div>
            </div>

            <div className="col-lg-8 offset-lg-1">
              {activeTab === "tab1" && <SocialMediaAccountManager />}
              {activeTab === "tab2" && <WordpressDeveloperTab />}
              {activeTab === "tab3" && <WebDesignerTab />}
              {activeTab === "tab4" && <WebDeveloperTab />}
              {activeTab === "tab5" && <GraphicDesignerTab />}
              {activeTab === "tab6" && <UIUXDesignerTab />}
              {activeTab === "tab7" && <PPTDesignerTab />}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JobTabs;
